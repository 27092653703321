import { readState } from "@/__main__/app-state.mjs";
import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import { setRoute } from "@/__main__/router.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { GAME_SYMBOL_MARVEL_RIVALS } from "@/game-marvel-rivals/definition-symbol.mjs";
import {
  EVENT_MARVEL_RIVALS_ENTER_GAME,
  EVENT_MARVEL_RIVALS_EXIT_GAME,
} from "@/game-marvel-rivals/events.mjs";
import { devDebug } from "@/util/dev.mjs";
import { findGameSymbol } from "@/util/game-route.mjs";

type IsRunningEvent = {
  isRunning: boolean;
  steamId?: number;
};

enum MatchStates {
  PENDING = 0,
  LOADING = 1,
  SELECTING = 2,
  PREPARING = 3,
  FIGHTING = 4,
  SLOWMOTION = 5,
  TRANSITION = 6,
  QUITTING = 7,
  END = 8,
}

type MatchStateEvent = {
  match_state: MatchStates;
  session_id: string;
  timestamp: number;
};

async function initMarvelRivals() {
  await initEvents;

  handleMessage(EVENTS.MARVEL_RIVALS_IS_RUNNING, (payload: IsRunningEvent) => {
    const { isRunning } = payload;
    devDebug("[Marvel Rivals] Received MARVEL_RIVALS_IS_RUNNING", payload);
    if (!isRunning) {
      setVolatileKV("currentGameTuple", null);
      return;
    }

    setVolatileKV("currentGameTuple", [GAME_SYMBOL_MARVEL_RIVALS]);
    if (findGameSymbol() !== GAME_SYMBOL_MARVEL_RIVALS) {
      setRoute("/marvel-rivals/tierlist");
    }
  });

  handleMessage(
    EVENTS.MARVEL_RIVALS_MATCH_STATE,
    (payload: MatchStateEvent) => {
      devDebug("[Marvel Rivals] Received MARVEL_RIVALS_MATCH_STATE", payload);
      const { match_state, session_id } = payload;
      switch (match_state) {
        case MatchStates.PREPARING:
          if (readState.volatile?.marvelRivalsLatestMatchId === session_id)
            return;
          eventBus.emit(EVENT_MARVEL_RIVALS_ENTER_GAME, {
            matchId: session_id,
          });
          setVolatileKV("marvelRivalsLatestMatchId", session_id);
          break;
        case MatchStates.QUITTING:
          eventBus.emit(EVENT_MARVEL_RIVALS_EXIT_GAME, {
            matchId: session_id,
          });
          setVolatileKV("marvelRivalsLatestMatchId", null);
          break;
        default:
          break;
      }
    },
  );

  // handleMessage(EVENTS.MARVEL_RIVALS_ON_PLAYER_STATS, (payload) => {
  //   devDebug("[Marvel Rivals] Received MARVEL_RIVALS_ON_PLAYER_STATS", payload);
  // });

  // handleMessage(EVENTS.MARVEL_RIVALS_ON_PLAYER_COMBAT, (payload) => {
  //   devDebug(
  //     "[Marvel Rivals] Received MARVEL_RIVALS_ON_PLAYER_COMBAT",
  //     payload,
  //   );
  // });
}

export default initMarvelRivals;
